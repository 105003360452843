<template>
<div>
  <autogestion-Paso-Uno class="landscape"></autogestion-Paso-Uno>
  <v-row>
    <v-col cols="12" style="height: 100px;"></v-col>
  </v-row>
  <v-container style="margin-top:75px">
      <v-row align="center"> 
        <v-col md="12">
          <h3 class="ml-1 mb-n2 colorCustom2--text">Ingrese la cuenta de Alumbrado, limpieza y conservación de la vía pública</h3>
        </v-col>
        <v-col md="12">             
          <v-text-field                   
            class="ml-auto"
            solo
            hide-details
            type="number"
            data-layout="normal"
            autocomplete="off"
            ref="abl"
            @input="onInputChange"
            :value="dataForm.cuenta"
          />     
          </v-col>  
          <v-col md="12">
            <SimpleKeyboard @onChange="onChange" @onKeyPress="onKeyPress" :input="dataForm.cuenta" :layout="$store.getters.getLayoutNumeric" :buttonTheme="$store.getters.getButtonThemeNumeric"/>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="8">
            <v-row>
              <v-col style="text-align:center">
                <botonVolver />
              </v-col>
              <v-col style="text-align:center">
                <botonBuscar @Click="Buscar" />
              </v-col>  
            </v-row>
          </v-col>
        </v-row>  
  </v-container>
  <mensajeCerrarDialog ref="mensajeCerrarDialog"/>
  <mensajeErrorDialog ref="mensajeErrorDialog"/>

</div>
</template>
<script>
import SimpleKeyboard from '../components/teclados/KeyBoard.vue' 
import '../components/teclados/dark-theme.css'
  export default {
    components: {
      SimpleKeyboard,
      autogestionPasoUno: () => import('../components/autogestionPasoUno.vue'),
    },
    data () {
      return {
        dataForm: {
          cuenta: '',
        },
      }
    },    
    mounted () {     
      this.$refs.abl.focus()  
    },    
    methods: {
      onChange(input) {  
        this.dataForm.cuenta = input;
      },
      onKeyPress(button) {        
        console.log("button", button);
      },
      onInputChange(input) {
        this.dataForm.cuenta = input;
      },      
      Buscar: async function () {    
        console.log('mensajes: ',this.$store.getters.getMensajes)
        let error = false
        var existe = false     
        await this.$store.dispatch('axiosMod/getData', { url: '/SAT_WS/rest/cuentas/existe/' + this.dataForm.cuenta + '/ABL?td=undefined' }).then((response) => { 
          existe = response.existe
          if (!existe) {
            this.$refs.mensajeCerrarDialog.open(this.$store.getters.getMensajes["noCuenta"])      
           }          
        }).catch(() => {
          error = true
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
        })   

        if (existe && !error) {                    
          this.$router.push({ name: 'cuotasGeneral', params: { tributo: 'ALCVP Y SV', cuenta:this.dataForm.cuenta }})
        }    
      },
    },
  }
</script>
<style scoped>
.v-application .primary--text {
    color: inner !important;
}
</style>